.admin-details-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 0px;
  margin-left: 0px;
}

.settings-dialog {
  min-width: 450px;
  min-height: 250px;
}

.bottom-grey-border {
  border-bottom: 1px solid lightgrey;
}
