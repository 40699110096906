.user-info {
  .user-details {
    margin: 25px 0;
  }

  .user-pic {
    width: 125px;
    height: 125px;
    border-radius: 10px;
  }

  .details {
    padding-left: 0;

    .label {
      padding-left: 0;
    }
  }
}

.write-user-role {
  min-width: 50%;

  .role-selector {
    width: 100%;
    margin-top: 40px;

    .role-selector-dd {
      width: 100%;
    }
  }

  .user-role-step {
    padding-top: 10px;

    .step-card {
      margin: 2px;
    }

    .warn-message {
      color: $primary-color;
    }
  }
}

.user-permissions-wrapper {
  width: 100%;
  height: 300px;
  overflow: auto;
  margin: 12px 0 34px 0;
  padding: 6px 10px;
}

.iconSize {
  font-size: 30px;
}

.username-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.username-col {
  flex: 0 0 55%;
  max-width: 55%;
}

.md-icon-button-slim {
  margin-bottom: 0 !important;
  padding: 0 0 17px 0 !important;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
