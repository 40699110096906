@import 'colors';

// element's colorisation
$main-nav-background: $primary-color;
$user-profile-background: $accent-color;
$nav-menu-hover: $accent-color;

$list-row-divider-color: $grey-400;
$list-row-divider-hover-color: $accent-color;
$list-row-hover: $grey-100;

$code-background-color: $grey-100;

$inactive-color: $grey-100;

$card-sidenav-background: $accent-color;
$card-sidenav-icon: $grey-100;

$table-row-hover: $grey-100;

// fonts

$font-opensans: 'Open Sans', sans-serif;
$font-opensans-italic: OpenSans-Italic;

$font-weight-bold: bold;
$font-weight-regular: normal;
$font-weight-light: 200;

$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-big: 24px;
$font-size-30: 30px;
$font-size-38: 38px;
$font-size-72: 72px;
