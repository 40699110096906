@import "../definitions/variables";
@import "administration";
@import "dashboard";
@import "entity-types";
@import "users";
@import "user-profile";
@import "user-permissions";
@import "role-permissions";
@import "role-entity-types";
@import "role-details";
@import "roles";
@import "error/forbidden";

.hidden {
  display: none !important;
  width:0 !important;
  height:0 !important;
}

.entity-table {

  margin-bottom: 80px;

  .inactive {
    opacity: 0.5;
  }

  tbody {
    tr:hover {
      background-color: $table-row-hover;
    }
  }
}

#search-input{
  margin-top: 28px;
}

.selectHeader .header-searchbox {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0;
}

.selectHeader .select-header {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  padding-left: 10.667px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}

.selectHeader md-content._md {
  max-height: 240px;
}
