@import '../definitions/variables';

.menu-button {
  cursor: pointer;
  color: white;
  padding-left: 12px;
}

.side-nav.open {
  width: 200px !important;
}

.side-nav{
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 13px;
  padding-right: 15px;
  background-color: $main-nav-background;
  height: 100%;
  width: 50px;
  transition: width 1s;
  color: white;
  overflow: hidden;
  z-index: 79;

  .side-nav-title {
    padding-left: 30px;
    width: 165px;
    height: 150px;
    text-align: center;
    margin:auto;

    .side-nav-content {
      margin-top: 40px;
      line-height: 1.5;
      display: inline-block;
      vertical-align: middle;
      font-size: 24px;
      font-weight: 300;
    }
  }

  .sidenav-menu{
    padding: 0;
    width: 300px;

    .is-active{
      background-color: $nav-menu-hover !important;
    }

    .side-nav-menu-item {
      padding: 15px 0 15px 12px;
      display: block;
      border-bottom: solid 1px #707070;
      cursor: pointer;

      &:hover {
        background-color: $nav-menu-hover;
      }

      .menuitem-description {
        position: relative;
        top: -5px;
        left: 30px;
      }
    }
  }
}
