@import '../definitions/variables';

.header {
  display: flex;
  align-items: center;
  color: black;
  padding: 0 0 0 40px;
  background-color: $main-nav-background;
  border-bottom: 1px solid black;  
  height: 50px;

  .header-title {
    flex: 1;
    font-size: 1.5rem;
    margin: 1rem;

    span {
      background-color: transparent;
      border: none;
      font-size: 1em;
      color: white;
    }
  }

  .user-profile {
    position: relative;

    .user-icon {
      max-width: 40px;
      max-height: 40px;
      border-radius: 25px;
    }
  }
}
