@import "../definitions/variables";

.users-list {
  .user-icon {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    margin: 10px 0;
  }
}

.write-user {
  .user-icon {
    width: 150px;
    height: 150px;
    border-radius: 150px;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
