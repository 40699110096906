#search-input {
  margin-top: 28px;
}

.role-sliders {

  md-switch {
    width: 100px;
  }

  .risk-level {
    margin: 7px 0 0 40px;
    md-slider {
      &:hover {
        cursor: grab;
      }
      &:active {
        cursor: grabbing;
      }
    }
    label {
      margin: 12px 0 0 12px;
    }
  }
}
