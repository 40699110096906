.manage-role-entity-types {
  min-width: 50%;

  .entity-type-action-selector {
    width: 100%;
    margin-top: 40px;

    .entity-type-action-selector-dd {
      width: 100%;
    }
  }

  .action-selector {
    margin-top: 50px;
  }
}
