section.dashboard {

  > md-card {
    margin: 0px;
    padding: 8px;
    border: none;
    box-shadow: none;

    md-content {
      height: 100%;
      padding: 15px;
      overflow: auto;
      box-shadow: 0 0 5px 5px #EDEDED;
    }
  }

  .gap-border {
    border-bottom: 1px solid lightgray;
    margin-bottom: 5px;
    span {
      font-size: 12px;
      color: #7c7c7c;
    }
  }

  .gap {
    width: 100%;
    margin-bottom: 20px;
  }

  .big-icon {
    font-size: 64px;
  }

  .metric-total {
    font-weight: bold;
    padding-left: 10px;
    font-size: 22px;
    color: #333;
  }

  .metric-total-label {
    color: #333;
    padding-left: 10px;
    ont-size: 18px;
  }


  .metric-entry {
    padding: 0px 0px 10px 10px;
    display: grid;
    .metric-entry-result {
      font-size: 18px;
      font-weight: bold;
    }
    .metric-entry-result-label {
      font-size: 12px;
      color: #7c7c7c;
    }
  }

  .w100 {
    width: 100%;
  }


}
