@import 'components/sidenav';
@import 'components/header';
@import 'components/footer';
@import 'components/login';
@import 'components/_notifications.scss';
@import 'definitions/variables';
@import 'modules/main';


html, body, #root {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  width: 100vw;
  height: 100vh;
}

body, h1, h2, h3 {
  font-weight: 300
}

h1 {
  font-size: $font-size-30;
  font-weight: $font-weight-regular;
}

h2 {
  font-size: $font-size-big;
  font-weight: $font-weight-bold;
}

b, strong {
  font-weight: bold;
}

button {
  border: none;
  background-color: transparent;
}

a {
  color: white;
  text-decoration: none;

  &:hover {
    color: #5aadbb;
  }
}


md-dialog.big-dialog{
  min-width: 50%;
  min-height: 50%;
  .bottom-fix {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
  }
}

.centered-text {
  display: block;
  text-align: center
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.main {
  margin-left: 50px;
  height: 100%;
  width: calc(100% - 50px);
  overflow: auto;
  background-color: rgb(250,250,250);
}

.divider {
  height: 1px!important;
  background: #e4e4e4!important;
}

.z-depth {
    box-shadow: 0 6px 6px rgba(10,16,20,.15), 0 0 2px rgba(10,16,20,.12) !important;
    transform: translate3d(0,0,0);
    transition: background .4s cubic-bezier(.25,.8,.25,1),box-shadow 280ms cubic-bezier(.4,0,.2,1);
}

.z-depth:hover {
    box-shadow: 0 8px 8px rgba(10,16,20,.24), 0 0 8px rgba(10,16,20,.12) !important;
    transform: translate3d(0,0,0);
    transition: background .4s cubic-bezier(.25,.8,.25,1),box-shadow 280ms cubic-bezier(.4,0,.2,1);
}

.cursor-pointer {
  cursor: pointer;
}

.user-link {
  cursor: pointer;
  text-decoration: underline;
  color: blue !important;
}

.gap-border {
  border-bottom: 1px solid lightgray;
  margin-bottom: 5px;
  span {
    font-size: 12px;
    color: #7c7c7c;
  }
}

.gap {
  border-bottom: 1px solid lightgray;
  margin: 0 20px;
}

.big-icon {
  font-size: 64px;
}

.metric-total {
  font-weight: bold;
  padding-left: 10px;
  font-size: 22px;
  color: #333;
}

.metric-total-label {
  color: #333;
  padding-left: 10px;
  font-size: 18px;
}

.metric-entry {
  padding: 0px 0px 10px 10px;
  display: grid;
  .metric-entry-result {
    font-size: 18px;
    font-weight: bold;
  }
  .metric-entry-result-label {
    font-size: 12px;
    color: #7c7c7c;
  }
}

.no-outline:focus {
  outline: none;
}

.gap {
  border-bottom: 1px solid lightgray;
}

.presentation-card-margin {
  margin: 40px 0;
}

.presentation-card-icon-margin {
  margin: 0 80px 0 20px;
}

.info-card {
  max-width: calc(50% - 16px) !important;
}

.default-card md-content {
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
}

.no-shadow {
  box-shadow: none !important;
}

.selectdemoSelectHeader {
  /* Please note: All these selectors are only applied to children of elements with the 'selectdemoSelectHeader' class */
}

.selectdemoSelectHeader .demo-header-searchbox {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0;
}

.selectdemoSelectHeader .demo-select-header {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  padding-left: 10.667px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}

.selectdemoSelectHeader md-content._md {
  max-height: 240px;
}

.user-icon {
  width: 50px;
  height: 50px;
  border-radius: 30px;
  margin: 10px 0;
}

.redLabel {
  color: red;
}

.yellowLabel {
  color: #FFD92D;
}

.centered-circular-progress {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
