.forbidden {

  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h1 {
      font-family: 'Kanit', sans-serif;
      font-size: 186px;
      font-weight: 200;
      margin: 0px;
      background: linear-gradient(130deg, #ffa34f, #ff6f68);
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      text-transform: uppercase;
    }

    h2 {
      font-family: 'Kanit', sans-serif;
      font-size: 33px;
      font-weight: 200;
      text-transform: uppercase;
      margin-bottom: 35px;
      letter-spacing: 3px;
    }

    p {
      font-family: 'Kanit', sans-serif;
      font-size: 16px;
      font-weight: 200;
    }

    a {
      cursor: pointer;
      font-family: 'Kanit', sans-serif;
      color: #ff6f68;
      font-weight: 200;
      text-decoration: none;
      border-bottom: 1px dashed #ff6f68;
      border-radius: 2px;
    }
  }

}
