#login-page {
  height: 100%;
  margin: 0;

  .background-image {
    /* The image used */
    background-image: url("../../images/bg-warehouse.png");
    /* Add the blur effect */
    filter: blur(6px);
    -webkit-filter: blur(6px);
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .main-content {
    font-family: 'Roboto Medium', sans-serif;
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    padding: 20px;
    /* size */
    width: 450px;
    height: 500px;
    /* background & border*/
    border-radius: 26px;
    box-shadow: 0 3px 4px 2px rgba(119, 117, 117, 0.5);
    background-color: #ffffff;
    /* background images */
    background-image: url("../../images/top-left-frame.svg"), url("../../images/bottom-right-frame.svg");
    background-position: -1% -1%, 101% 101%;
    background-repeat: no-repeat, no-repeat;

    .content-header {
      margin-top: 80px;
      h1 {
        font-size: 28px;
        font-weight: 500;
        color: #4a4a4a;
        margin-top: 10px;
        margin-bottom: 5px;
      }
      p {
        font-size: 18px;
        color: #828282;
        font-weight: 400;
      }
    }

    .content-container {
      margin: 70px 0;
    }
    //.content-buttons {
    //  margin-top: 50px;
    //  button {
    //    width: 237px;
    //    height: 40px;
    //    border-radius: 4px;
    //    box-shadow: 0 2px 4px 1px rgba(159, 159, 159, 0.5);
    //    background-color: #ffffff;
    //    margin: 8px auto;
    //    text-align: left;
    //    span {
    //      font-size: 16px;
    //      font-weight: 500;
    //      font-style: normal;
    //      font-stretch: normal;
    //      line-height: normal;
    //      letter-spacing: normal;
    //      color: #828282;
    //    }
    //  }
    //  .google-button {
    //    img {
    //      margin-left: 21px;
    //      margin-right: 20px;
    //      width: 20px;
    //    }
    //  }
    //  .jumia-button {
    //    img {
    //      margin-left: 22px;
    //      margin-right: 22px;
    //    }
    //  }
    //}

    .content-circular-progress {
      margin-top: 20px;
      height: 30px;
    }

    .content-footer {
      margin-top: 20px;
      font-size: 10px;
      font-weight: 500;
      text-align: center;
      color: #828282;
    }

  }
}
